
/* Grig  */
.row::before,
.row::after {
  display: table;
  content: " ";
  clear: both;
}
.row {
  display: flex;
  flex-wrap: wrap;
}

.one,
.one-third,
.two-thirds,
.one-fourth,
.two-fourth,
.three-fourth,
.half {
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.md-one,
.md-one-third,
.md-two-thirds,
.md-one-fourth,
.md-two-fourth,
.md-three-fourth,
.md-half {
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.column {
  padding-bottom: 1px;
}
.column:after {
  content: "";
  position: absolute;
  height: 100%;
}

/*
* Any styling that should be applied to screen widths larger
* than a mobile device: tablet, laptop, desktop, etc.
*/
@media only screen and (min-width: 768px) {
  .sm-one {
    width: 100%;
  }
  .sm-half {
    width: calc(100% / 2);
  }
  .sm-one-third {
    width: calc(100% / 3);
  }
  .sm-one-fourth {
    width: calc(100% / 4);
  }
  .sm-two-fourth {
    width: calc(100% / 4 * 2);
  }
  .sm-three-fourth {
    width: calc(100% / 4 * 3);
  }
  .sm-two-thirds {
    width: calc(100% / 3 * 2);
  }
  .column {
    float: left;
    padding-top: 1px;
  }
  .row.right .column {
    float: right;
  }
}

@media only screen and (min-width: 992px) {
  .md-one {
    width: 100%;
  }
  .md-half {
    width: calc(100% / 2);
  }
  .md-one-third {
    width: calc(100% / 3);
  }
  .md-one-fourth {
    width: calc(100% / 4);
  }
  .md-two-fourth {
    width: calc(100% / 4 * 2);
  }
  .md-three-fourth {
    width: calc(100% / 4 * 3);
  }
  .md-two-thirds {
    width: calc(100% / 3 * 2);
  }
  .column {
    float: left;
    padding-top: 1px;
  }
  .row.right .column {
    float: right;
  }
}



@media only screen and (min-width: 768px) {
  .one {
    width: 100%;
  }
  .half {
    width: calc(100% / 2);
  }
  .one-third {
    width: calc(100% / 3);
  }
  .one-fourth {
    width: calc(100% / 4);
  }
  .two-fourth {
    width: calc(100% / 4 * 2);
  }
  .three-fourth {
    width: calc(100% / 4 * 3);
  }
  .two-thirds {
    width: calc(100% / 3 * 2);
  }
  .column {
    float: left;
    padding-top: 1px;
  }
  .row.right .column {
    float: right;
  }
}

@media only screen and (max-width: 992px) {
  .md.one,
  .md.one-third,
  .md.two-thirds,
  .md.three-fourth,
  .md.two-fourth,
  .md.one-fourth,
  .md.half {
    width: 100%;
  }
}







































