.fb-our-few-projects {

  padding-top: 130px;
  padding-bottom: 130px;
  position: relative;

  @media (max-width: 767px) {
    display: none;
  }

  @media (max-width: 992px) {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  @media (max-width: 550px) {
    padding-top: 60px;
    padding-bottom: 30px;
  }

  .title {
    text-align: center;
  }

  .select-job {
    margin-top: 40px;

    ul {

      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;

        &.active {
          button {
            background: @light-blue;
          }
        }
      }

    }
  }

  .display-container .display .image {
    opacity: 1;
  }
  .display-container {

    position: relative;

    .frame-box {
      width: 633px;
      margin-left: auto;
      margin-right: auto;
      position: relative;

      iframe {
        width: 1266px;
        height: 800px;
        position: relative;
        top: 25px;
        left: 20px;
        background-color: white;
        z-index: 2;
        border: 0;
        -ms-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -o-transform: scale(0.5);
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        -ms-transform-origin: 0 0;
        -moz-transform-origin: 0 0;
        -o-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;

        display: none;

        &.active {
          display: initial;
        }
      }
    }

    .display {
      position: absolute;
      background: transparent;
      top: 100px;
      width: 100%;
      z-index: 2;
      text-align: center;
      height: 520px;
      transition: height 0.5s ease-in-out;

      .image {
        width: 100%;
        height: 100%;
        background: no-repeat url(../img/monitors/macbook_pro_f_b.svg) bottom center transparent;
        background-size: auto;
        background-position: top center;
        opacity: 1;
        overflow: hidden;
      }
    }

    &.macbook {

        //
      .display {
        margin-bottom: -2rem;
        .image {
          opacity: 1;

          //@media (max-width: 992px) {
          //  background-size: 600px;
          //}
        }

      }

      iframe {
        //@media (max-width: 992px) {
        //  -ms-transform: scale(0.3);
        //  -moz-transform: scale(0.3);
        //  -o-transform: scale(0.3);
        //  -webkit-transform: scale(0.3);
        //  transform: scale(0.3);
        //
        //  width: 1340px;
        //  height: 850px;
        //  top: 15px;
        //  left: 162px;
        //}
      }
    }

    &.ipad {
      iframe {
        width: 1106px;
        height: 836px;
        top: 25px;
        left: 40px;
      }
      .display {
        .image {
          width: 100%;
          height: 100%;
          background: no-repeat url(../img/monitors/ipad_F_B.svg) top center transparent;
          background-size: auto;
          background-position: top center;
          z-index: 3;
          position: relative;
          opacity: 1;
        }
      }
    }

    &.iphone {
      iframe {
        width: 354px;
        height: 750px;
        top: 34px;
        left: 72px;
        transition: height 0.2s ease-in-out;
        -ms-transform: scale(0.6);
        -moz-transform: scale(0.6);
        -o-transform: scale(0.6);
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
      }
      .display {
        .image {
          width: 100%;
          height: 100%;
          background: transparent;
          opacity: 1;
          transition: height 0.5s ease-in-out;
          &:after {
            position: absolute;
            top: 0;
            display: block;
            content: '';
            width: 100%;
            height: 100%;
            background: no-repeat url(../img/monitors/iphone_x_F_B.png) top center transparent;
            background-size: auto;
            z-index: 20;
            pointer-events: none;
          }
        }
      }
    }

    .menu-display {
      width: 100%;
      position: absolute;
      z-index: 3;
      text-align: center;
      padding-bottom: 100px;

      ul.list-inline {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        width: auto;
        text-align: center;
        li.list-icons {
          padding-left: 20px;
          padding-right: 20px;
          .off {
            display: none;
          }

          img {
            cursor: pointer;
          }
        }
      }
    }
  }
}


//@media (max-width: 1750px) {
//  .front-back-portfolio-page .display {
//    right: 100px;
//  }
//}
//@media (max-width: 1550px) {
//  .front-back-portfolio-page .display {
//    right: 50px;
//  }
//}
//@media (max-width: 1450px) {
//  .front-back-portfolio-page .display {
//    right: 0;
//    height: 420px;
//  }
//}
//@media (max-width: 1300px) {
//  .front-back-portfolio-page .display {
//    right: 0;
//    width: 800px;
//  }
//}
//
//@media (max-width: 1200px) {
//  .front-back-portfolio-page .display {
//    right: 0;
//    width: 700px;
//  }
//}
//
//@media (max-width: 1100px) {
//  .front-back-portfolio-page .display {
//    right: 0;
//    height: 330px;
//    /*width: 600px;*/
//  }
//}



//
//@media (max-width: 1450px) {
//  .front-back-portfolio-page .display .image {
//    background-size: 800px;
//    background-position: top center;
//  }
//  .front-back-portfolio-page .display-container.macbook iframe {
//    width: 1070px;
//    height: 690px;
//    top: 15px;
//    left: 200px;
//  }
//  .front-back-portfolio-page .ipad .display .image {
//    background-size: 620px;
//    background-position: top center;
//  }
//
//  .front-back-portfolio-page .display-container.ipad iframe {
//    width: 910px;
//    height: 696px;
//    top: 20px;
//    left: 222px;
//  }
//
//  .front-back-portfolio-page .display-container.iphone iframe{
//    height: 690px;
//  }
//
//  .front-back-portfolio-page .iphone .display {
//    height: 620px;
//    transition: 0.3s ease-in-out;
//  }
//
//  .front-back-portfolio-page .iphone .display .image {
//    height: 520px;
//    transition: 1s ease-in-out;
//  }
//
//}

//
//@media (max-width: 1300px) {
//  .front-back-portfolio-page .display-container.macbook iframe {
//    left: 150px;
//  }
//
//  .front-back-portfolio-page .display-container.ipad iframe {
//    left: 172px;
//  }
//
//  .front-back-portfolio-page .display-container.iphone iframe {
//    left: 295px;
//  }
//}
//@media (max-width: 1200px) {
//  .front-back-portfolio-page .display-container.macbook iframe {
//    left: 100px;
//  }
//
//  .front-back-portfolio-page .display-container.ipad iframe {
//    left: 122px;
//  }
//
//  .front-back-portfolio-page .display-container.iphone iframe {
//    left: 245px;
//  }
//}
//
//@media (max-width: 992px) {
////
//  .front-back-portfolio-page .macbook .display .image {
//    background-size: 600px;
//  }
////  .front-back-portfolio-page .ipad .display .image {
////    background-size: 500px;
////  }
////  .front-back-portfolio-page .iphone .display .image {
////    /*background-size: 600px;*/
////  }
////
//  .front-back-portfolio-page .display-container.macbook iframe {
//    -ms-transform: scale(0.3);
//    -moz-transform: scale(0.3);
//    -o-transform: scale(0.3);
//    -webkit-transform: scale(0.3);
//    transform: scale(0.3);
//
//    width: 1340px;
//    height: 850px;
//    top: 15px;
//    left: 162px;
//  }
////
//  .front-back-portfolio-page .display-container.ipad iframe {
//    -ms-transform: scale(0.3);
//    -moz-transform: scale(0.3);
//    -o-transform: scale(0.3);
//    -webkit-transform: scale(0.3);
//    transform: scale(0.3);
//
//    width: 1240px;
//    height: 940px;
//    top: 15px;
//    left: 165px;
//  }
//
//  .front-back-portfolio-page .display-container.iphone iframe {
//    left: 245px;
//  }
//}


//
//.front-back-portfolio-page .menu-display {
//  width: 600px;
//  position: absolute;
//  z-index: 3;
//  right: 156px;
//}

//@media (max-width: 1300px) {
//  .front-back-portfolio-page .menu-display {
//    right: 106px;
//  }
//}
//@media (max-width: 1200px) {
//  .front-back-portfolio-page .menu-display {
//    right: 56px;
//  }
//}
//
//
//
//.front-back-portfolio-page .menu-display ul {
//  padding: 0;
//  margin: 0;
//}
