html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

.container {
  max-width: @container-max-width;
  margin: 0 auto;
  padding: 0;
  padding-left: @container-padding;
  padding-right: @container-padding;
}

@media (max-width: 1400px) {
  .container {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (max-width: 1300px) {
  .container {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 1200px) {
  .container {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (max-width: 768px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}


a {
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
  padding: 0;
  color: @grey;
}

h1 {
  font-size: 48px;
  @media(max-width: 992px) {
    font-size: 36px;
  }
  @media(max-width: 550px) {
    font-size: 28px;
  }
}

h2 {
  font-size: 36px;
  @media(max-width: 992px) {
    font-size: 28px;
  }
  @media(max-width: 550px) {
    font-size: 24px;
  }
}

h3 {
  font-size: 28px;
  @media(max-width: 992px) {
    font-size: 24px;
  }
  @media(max-width: 550px) {
    font-size: 20px;
  }
}

h4 {
  font-size: 24px;
  @media(max-width: 992px) {
    font-size: 20px;
  }
  @media(max-width: 550px) {
    font-size: 18px;
  }
}

p {
  font-size: 18px;
  line-height: 130%;
  color: @grey;

  @media(max-width: 992px) {
    font-size: 16px;
    line-height: 150%;
  }
  @media(max-width: 550px) {
    font-size: 14px;
    line-height: 170%;
  }
}



ul {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  outline: none;
  background: 0 0;
}

li {
  list-style-type: none;
  color: @grey;
}
