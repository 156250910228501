

@font-face {
  font-family: "SF-Pro-Display";
  src: url(@font-light);
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "SF-Pro-Display";
  src: url(@font-regular);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "SF-Pro-Display";
  src: url(@font-medium);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "SF-Pro-Display";
  src: url(@font-bold);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "SF-Pro-Display";
  src: url(@font-black);
  font-weight: 900;
  font-display: swap;
}

body {
  font-family: @font-family;
}


a, button, p, ul , li , div {
  font-family: @font-family;
}




