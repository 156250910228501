/**************************\
  Basic Modal Styles
\**************************/
body.modal-open {
  overflow: hidden;
}

.modal {

}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__global {
  overflow-y: auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-top: auto;
  margin-bottom: auto;
}

.modal__container {
  background: @blue-gradient;
  padding: 30px 30px;
  max-width: 700px;
  max-height: auto;
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: @white;

  .modal__title {
    &.success {
      display: none;
    }
    &.error {
      display: none;
    }
  }
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  //font-size: 1.25rem;
  //line-height: 1.25;
  box-sizing: border-box;
  color: @white;
}

.modal__close {
  background: transparent;
  border: 0;
  outline: 0;
}

.modal__header .modal__close:before {
  content: "\2715";
  color: @white;
  font-size: 20px;
}

.modal__content {
  margin-top: 30px;
  margin-bottom: 50px;
  line-height: 1.5;
  color: @white;
  p {
    color: @white;

    &.success {
      display: none;
    }
    &.error {
      display: none;
    }
  }
}

.modal__btn {
  font-size: .875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #e6e6e6;
  color: rgba(0,0,0,.8);
  border-radius: .25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform .25s ease-out;
  transition: transform .25s ease-out;
  transition: transform .25s ease-out,-webkit-transform .25s ease-out;
}

.modal__btn:focus, .modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

.modal__footer {
  text-align: center;

  button {
    margin-left: auto;
    margin-right: auto;
  }
}



/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes mmfadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
  to { transform: translateY(0); }
}

@keyframes mmslideOut {
  from { transform: translateY(0); }
  to { transform: translateY(-10%); }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}


.result-modal {
  .modal__title {
    width: 100%;
    text-align: center;
  }

  .modal__content {
    margin-bottom: 10px;
    p {
      text-align: center;
    }
  }

  &.success {

    p.success {
      display: block;
    }

    .modal__title.success {
      display: block;
    }
  }

  &.error {

    p.error {
      display: block;
    }

    .modal__title.error {
      display: block;
    }
  }
}
