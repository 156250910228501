.form-group {
  width: 100%;
  margin-top: 20px;
  position: relative;

  input, textarea {
    width: 100%;
  }

  label {
    font-size: 16px;
    color: white;
  }

  input {
    margin-top: 10px;
    padding: 16px 16px;
    border-radius: 2px;
    outline: 0;
    background: @white;
    opacity: 0.82;
    font-size: 18px;
    transition: background 0.3s,opacity 0.3s ease-in-out;
    border: 1px solid transparent;

    &:focus {
      opacity: 1;
    }
  }

  textarea {
    margin-top: 10px;
    padding: 16px 16px;
    border-radius: 2px;
    outline: 0;
    background: @white;
    opacity: 0.82;
    font-size: 18px;
    transition: background 0.3s,opacity 0.3s ease-in-out;
    border: 1px solid transparent;
    height: 300px;

    &:focus {
      opacity: 1;
    }
  }

  &.textarea-group {
    .error {
      position: absolute;
      margin: 0;
      padding: 0;
      bottom: 10px;
      right: 5px;
      font-size: 12px;
      line-height: 12px;
      color: red;
      display: none;
    }

  }

  .error {
    position: absolute;
    margin: 0;
    padding: 0;
    bottom: 4px;
    right: 5px;
    font-size: 12px;
    line-height: 12px;
    color: red;

    display: none;
  }


  &.invalid {

    input {
      background: rgba(255, 204, 206, 0.74);
      border-color: red;
      opacity: 1;
    }

    textarea {
      background: rgba(255, 204, 206, 0.74);
      border-color: red;
      opacity: 1;
    }

    .error {
      display: block;
    }
  }

  &.valid {
    input {
      background: rgba(205, 255, 228, 0.74);
      border-color: green;
      opacity: 1;
    }

    .error {
      display: none;
    }
  }
}
