.fb-letter-why-fb {

  padding-top: 130px;
  padding-bottom: 65px;
  position: relative;

  @media (max-width: 992px) {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (max-width: 550px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }


  .title {
    text-align: center;
    color: @grey;
  }

  .items {

    .item {
      position: relative;
      padding-top: 100px;

      @media (max-width: 992px) {
        padding-top: 80px;
      }

      @media (max-width: 768px) {
        padding-top: 70px;
      }

      @media (max-width: 550px) {
        padding-top: 60px;
      }



      .item-box {

        position: relative;
        width: 100%;

        .text {

          padding-left: 50%;

          @media (max-width: 768px) {
            padding-left: 0;
          }

          h4 {
            color: @grey;
            max-width: 430px;

            @media (max-width: 768px) {
              text-align: center;
              margin-left: auto;
              margin-right: auto;
            }
          }

          p {
            color: @grey;
            max-width: 430px;

            @media (max-width: 768px) {
              text-align: center;
              margin-left: auto;
              margin-right: auto;
            }
          }

          .image {
            position: absolute;
            left: 0;
            top: 0;
            width: 50%;
            text-align: center;

            @media (max-width: 768px) {
              width: 100%;
              position: static;
            }

            img {

              @media (max-width: 992px) {
                margin-top: 30px;
                max-width: 100%;
                padding-left: 50px;
                padding-right: 50px;
              }

            }
          }
        }
      }
    }
  }


}
