.fb-language-switcher {
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: -15px;
    margin-left: -15px;

    @media (max-width: 768px) {
      margin-right: 0;
      margin-left: 0;
    }
    @media (max-width: 500px) {
      justify-content: center;
    }
    li {
      text-align: center;
      padding-left: 15px;
      padding-right: 15px;
      a {
        text-decoration: none;
      }
    }
  }
}
