.who-is-it-for {

  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;

  @media (max-width: 992px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (max-width: 550px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }


  .title {
    text-align: center;
    color: @grey;
  }


  .items {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;

    .item {
      padding-left: 15px;
      padding-right: 15px;
      width: 33.333%;
      margin-top: 50px;

      @media (max-width: 992px) {
        width: 50%;
        padding-left: 0;
        padding-right: 0;
      }

      @media (max-width: 600px) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }

      .item-box {

        max-width: 300px;
        margin-left: auto;
        margin-right: auto;

        .item-content  {
          position: relative;
          height: 350px;
          overflow: hidden;

          .item-image {
            position: static;
            z-index: 1;
            top:0;
            left: 0;
            width: 100%;
            overflow: hidden;
            img {
              width: 100%;
            }
          }

          .item-description {
            position: absolute;
            z-index: 2;
            left: 0;
            bottom: 0;
            background: rgba(0, 74, 255, 0.75);
            color: @white;
            padding: 15px 15px 15px 15px;
            text-align: center;
            font-size: 15px;
            font-weight: 700;
          }
        }
      }
    }

  }
}
