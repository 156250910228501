.fb-letter-banner {

  padding-top: 130px;
  padding-bottom: 65px;
  position: relative;

  @media (max-width: 992px) {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  @media (max-width: 550px) {
    padding-top: 60px;
    padding-bottom: 30px;
  }

  .content-box {

    width: 100%;
    padding-left: 470px;
    position: relative;


    @media (max-width: 992px) {
      padding-left: 0;
      max-width: 630px;
      text-align: center;
      margin-right: auto;
      margin-left: auto;
    }

    .title {
      max-width: 630px;

      h1 {
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0.264706px;
        color: @grey;
      }
    }

    .image {
      position: absolute;
      top: 0;
      left: 22px;
      @media (max-width: 992px) {
        position: static;
        margin-top: 30px;
      }
      @media (max-width: 768px) {
        img {
          max-width: 100%;
        }
      }

    }

    .description {
      padding-top: 34px;
      p {
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0.264706px;
        color: @grey;
      }
    }

    .action {
      padding-top: 38px;

      @media (max-width: 992px) {
        text-align: center;
      }

      button {
        padding-left: 40px;
        padding-right: 40px;

        @media (max-width: 992px) {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
