.fb-letter-why-win {

  padding-top: 65px;
  padding-bottom: 65px;
  position: relative;

  @media (max-width: 992px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (max-width: 550px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }


  .title {
    text-align: center;
    color: @grey;
  }

  .items {

    .item {
      position: relative;
      padding-top: 100px;

      @media (max-width: 992px) {
        padding-top: 80px;
      }

      @media (max-width: 768px) {
        padding-top: 70px;
      }

      @media (max-width: 550px) {
        padding-top: 60px;
      }



      .item-box {

        position: relative;
        width: 100%;

        .text {

          padding-left: 50%;
          padding-right: 40px;

          @media (max-width: 768px) {
            padding-left: 0;
            padding-right: 0;
          }

          h4 {
            color: @grey;
            max-width: 430px;

            @media (max-width: 768px) {
              text-align: center;
              margin-left: auto;
              margin-right: auto;
            }
          }

          p {
            color: @grey;
            max-width: 430px;

            @media (max-width: 768px) {
              text-align: center;
              margin-left: auto;
              margin-right: auto;
            }
          }

          .image {
            position: absolute;
            left: 0;
            top: 0;
            width: 50%;
            text-align: center;

            @media (max-width: 768px) {
              width: 100%;
              position: static;
              text-align: center;
            }

            img {

              //@media (max-width: 992px) {
                max-width: 100%;
                padding-left: 50px;
                padding-right: 50px;
              //}

              @media (max-width: 768px) {
                margin-top: 30px;
              }

            }
          }
        }
      }

      &.right {
        .item-box {
          .text {

            padding-right: 50%;
            padding-left: 40px;

            @media (max-width: 768px) {
              padding-right: 0;
              padding-left: 0;
            }

            .image {
              position: absolute;
              left: 50%;
              top: 0;
              width: 50%;
              text-align: left;

              @media (max-width: 768px) {
                width: 100%;
                position: static;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }


}
