/**
 * Buttons
 */
a, button {
  cursor: pointer;

  &.btn {
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: normal;
    font-size: 15px;
    text-align: center;
    background: transparent;
    border: none;
    border-radius: 4px;
    min-width: 200px;
    height: 52px;

    padding-left: 20px;
    padding-right: 20px;

    &.btn-small {
      font-size: 14px;
      height: 47px;
      min-width: 200px;
    }
    &.btn-medium {
      font-size: 15px;
      height: 52px;
      min-width: 200px;
    }
    &.btn-large {
      font-size: 18px;
      height: 62px;
      min-width: 200px;
    }

    &.btn-blue {
      background: @blue;
      color: @white;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: @dark-blue;
      }
    }

    &.btn-white {
      background: @white;
      color: @black;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: darken(@white, 0.8);
      }

      &.btn-hover-light-blue {
        &:hover {
          background: @light-blue;
          color: @white;
        }
      }

      &.btn-hover-blue {
        &:hover {
          background: @blue;
          color: @white;
        }
      }

    }
  }
}
