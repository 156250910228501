
/**
Fonts
 */

@core : '..';

@font-light : '@{core}/fonts/sf-pro-display-light.otf';
@font-regular : '@{core}/fonts/sf-pro-display-regular.otf';
@font-medium : '@{core}/fonts/sf-pro-display-medium.otf';
@font-bold : '@{core}/fonts/sf-pro-display-bold.otf';
@font-black : '@{core}/fonts/sf-pro-display-black.otf';

@font-family: "SF-Pro-Display", "sans-serif";

@email-font: "Arial";



/**
Container
 */
@container-padding: 130px;
@container-max-width: 1440px;


/**
Colors
 */
@grey: #4A4A4A;
@blue: rgb(0, 74, 255);
@blue-gradient: linear-gradient(90deg, #004AFF 0%, #0081FF 125.68%);;
@light-blue: #24C9FC;
@dark-blue: #0036BA;
@white: #FFFFFF;
@black: #000000;
