.fb-letter-call-to-action {

  background: @blue;

  padding-top: 61px;
  padding-bottom: 75px;

  .title {
    text-align: center;
    h2 {
      text-align: center;
      color: @white;
    }
  }

  .action {
    text-align: center;
    padding-top: 57px;

    button {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .additional-info {
    text-align: center;
    padding-top: 50px;
    p {
      text-align: center;
      color: @white;
      margin: 0;
    }
  }
}
