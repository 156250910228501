.our-proposition {

  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;

  @media (max-width: 992px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (max-width: 550px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .content {
    border-top: 1px solid rgba(74, 74, 74, 0.4);
    border-bottom: 1px solid rgba(74, 74, 74, 0.4);
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;

    padding-top: 80px;
    padding-bottom: 80px;

    .title {
      text-align: center;
      color: @grey;
    }

    p {
      text-align: center;

      max-width: 400px;
      margin-right: auto;
      margin-left: auto;

    }

    ul {
      padding-top: 20px;
      padding-bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      li {
        width: auto;
        padding-left: 5px;
        padding-right: 5px;
        text-align: center;
        list-style-type: none;
        display: block;
        padding-top: 5px;
        padding-bottom: 5px;

        span {
          padding: 12px 15px;
          border-radius: 40px;
          font-size: 14px;
          background: @blue;
          color: @white;
          display: table;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }


}
